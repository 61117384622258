import {
  Dashboard,
  People,
  ViewColumn,
  Movie,
  // Restaurant,
  // ShoppingBasket,
  LocalOffer,
  CardGiftcard,
  Image,
  Reply,
  Settings,
  Category,
  Error,
  Payment,
  Fastfood,
  Toll,
  Notifications,
  PollOutlined
} from "@material-ui/icons";

export default {
  main: [
    {
      icon: Dashboard,
      primary: "Dashboard",
      path: "/dashboard/analytics",
      exact: false,
      dropdown: true
    },
    {
      icon: Movie,
      primary: "Movies",
      path: "/dashboard/movies",
      exact: false,
      dropdown: true
    },
    {
      icon: Image,
      primary: "Hero banners",
      path: "/dashboard/hero-banners",
      exact: false
    },
    {
      icon: LocalOffer,
      primary: "Promotions",
      path: "/dashboard/promotions",
      exact: false
    },
    // {
    //   icon: Layers,
    //   primary: "Categories",
    //   path: "/dashboard/hero-banners",
    //   exact: false
    // },
    // {
    //   icon: Restaurant,
    //   primary: "Food & Beverages",
    //   path: "/dashboard/fandb",
    //   exact: false
    // },
    // {
    //   icon: ShoppingBasket,
    //   primary: "Merchandise",
    //   path: "/dashboard/merchandise",
    //   exact: false
    // },
    {
      icon: Fastfood,
      primary: "Concessions",
      path: "/dashboard/concessions",
      exact: false,
      dropdown: true
    },
    {
      icon: Toll,
      primary: "Tickets Log",
      path: "/dashboard/purchased-tickets",
      exact: false
    },
    {
      icon: Toll,
      primary: "FH+ Event",
      path: "/dashboard/event-cinema",
      exact: false
    },
    // {
    //   icon: PollOutlined,
    //   primary: "Point System",
    //   path: "/dashboard/points",
    //   exact: false
    // },
    // {
    //   icon: Toll,
    //   primary: "Cube Booking Log",
    //   path: "/dashboard/cube-booking-logs",
    //   exact: false
    // },
    {
      icon: Notifications,
      primary: "Notifications",
      path: "/dashboard/notifications",
      exact: false
    },
    // {
    //   icon: Category,
    //   primary: "Categories",
    //   path: "/dashboard/categories",
    //   exact: false
    // },
    {
      icon: Reply,
      primary: "Referral links",
      path: "/dashboard/referral-links",
      exact: false
    },
    // {
    //   icon: CardGiftcard,
    //   primary: "Bulk Vouchers",
    //   path: "/dashboard/bulk-vouchers",
    //   exact: false
    // },
    {
      icon: People,
      primary: "Users",
      path: "/dashboard/users",
      exact: false,
      dropdown: true
    },
    {
      icon: ViewColumn,
      primary: "Membership Tiers",
      path: "/dashboard/tiers",
      exact: false
    },
    {
      icon: Settings,
      primary: "Admin Settings",
      path: "/dashboard/admin",
      exact: false
      // dropdown: true
    },
    {
      icon: Payment,
      primary: "Payment Logs",
      path: "/dashboard/payment-logs",
      exact: false
    },
    {
      icon: Error,
      primary: "Error Logs",
      path: "/dashboard/error-logs",
      exact: false
    }
  ]
};
