import api from "./instance";

export default {
  fetchTickets: token =>
    api
      .get("/ticket/ticket-breakdown", {
        headers: { Authorization: token }
      })
      .then(res => res),
  fetchTicketTypes: (token, cinemaId, cinemaScreenSectionId, showtimeId) =>
    api
      .get(
        `/ticket/types?cinema_id=${cinemaId}&show_time=${showtimeId}&cinema_screen_section_id=${cinemaScreenSectionId}`,
        {
          headers: { Authorization: token }
        }
      )
      .then(res => res)
};
