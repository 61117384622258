import React from "react";

const Increment = props => (
  <svg
    {...props}
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="11" cy="10" rx="10.5" ry="10" fill="#1F1F26" />
    <path d="M11.5 5V15" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M6.5 10H16.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Increment;
