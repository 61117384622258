import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import eventsApi from "../../../api/event";
import moment from "moment";
import { Button, CircularProgress } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import ticketsApi from "../../../api/tickets-api";

const MovieSelection = ({
  selections,
  cinema,
  date,
  jwt,
  handleAddAction,
  setPrice,
  onRemoveSelection
}) => {
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [selectionsData, setSelectionsData] = useState([]);

  useEffect(() => {
    if (!cinema || !date) return;
    eventsApi.getCinemaMovies(cinema, date, date, jwt).then(res => {
      const movies = res?.data?.data;
      setMovies(movies);
    });
  }, [cinema, date, jwt]);

  useEffect(() => {
    async function fetchSelections() {
      setLoading(true);
      const newSelections = await Promise.all(
        selections?.map(async selection => {
          const movie = movies?.find(
            item => `${item?.movie_id}` === `${selection.movie}`
          );

          const showtime = movie?.show_times?.find(
            item => `${item?.show_time_id}` === `${selection.showtime}`
          );

          const res = await ticketsApi.fetchTicketTypes(
            jwt,
            cinema,
            showtime?.cinema_screen_id,
            showtime?.show_time_id,
            jwt
          );

          const tickets = res?.data?.data?.tickets?.map(ticket => ({
            ticket_id: `${ticket?.product_id}`,
            ticket_name: ticket?.product_name,
            ticket_price: `${ticket?.product_price}`,
            display_price: `${ticket?.product_price}`
          }));

          const ticket = tickets?.find(
            item => `${item?.ticket_id}` === `${selection.ticket}`
          );

          const newSelection = {
            id: selection?.id,
            movie: {
              name: movie?.movie_name,
              id: movie?.movie_id,
              img: movie?.movie_poster
            },
            showtime: {
              id: showtime?.show_time_id,
              name: moment(showtime?.show_time)?.format("hh:mmA")
            },
            ticket: {
              name: ticket?.ticket_name,
              id: ticket?.ticket_id,
              price: ticket?.ticket_price
            }
          };

          return newSelection;
        })
      );

      setLoading(false);

      setSelectionsData(newSelections);
    }
    fetchSelections();
  }, [selections, movies]);

  return (
    <div className={classes.selectMovies}>
      {selectionsData?.length ? (
        <ul className={classes.selectionItems}>
          {selectionsData?.map((selection, index) => (
            <li key={selection?.id} className={classes.selectionItem}>
              <img src={selection?.movie?.img ?? ""} alt="" />
              <div className="selection-header">
                <p className="movie-title">{selection?.movie?.name}</p>
                <button
                  type="button"
                  onClick={() => {
                    setPrice(
                      prevPrice =>
                        prevPrice - parseInt(selection?.ticket?.price ?? 0)
                    );
                    onRemoveSelection(selection?.id);
                  }}
                >
                  <Delete color="secondary" />
                </button>
              </div>
              <p className="movie-pill">{selection?.showtime?.name}</p>
              <p className="movie-pill">{selection?.ticket?.name}</p>
              <p className="movie-pill">
                ₦{parseInt(selection?.ticket?.price)}
              </p>
            </li>
          ))}
        </ul>
      ) : null}
      {loading && <CircularProgress size={24} />}

      {
        <div className="action-btn">
          <p>Select Movies </p>
          <Button
            onClick={() => {
              handleAddAction();
            }}
            color="primary"
            variant="outlined"
            type="button"
          >
            + ADD MOVIE{" "}
          </Button>
        </div>
      }
    </div>
  );
};

export default MovieSelection;
