import React from "react";
import EventForm from "./EventForm";
import { connect } from "react-redux";
import eventApi from "../../../api/event";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getRequestError } from "../../../utils/functions";
import { useDispatch } from "react-redux";
import types from "../../../redux/types";

const CreateEvent = ({ user }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  return (
    <EventForm
      jwt={user.jwt}
      event={{
        title: "",
        description: "",
        category: "",
        image: "",
        selections: [],
        concessions: [],
        date: moment().format("YYYY-MM-DD"),
        time: "00:00",
        price: ""
      }}
      formTitle={"Create an Event"}
      handleEvent={async data => {
        try {
          await eventApi.addEvent(`Bearer ${user.jwt}`, data);
          history.goBack();
        } catch (e) {
          dispatch({
            type: ALERT_OPEN,
            payload: {
              severity: "error",
              message: getRequestError(e)
            }
          });
          return e;
        }
      }}
      type={"add"}
    />
  );
};

const mapStateToProps = state => ({
  user: state.user.user
});
export default connect(mapStateToProps)(CreateEvent);
