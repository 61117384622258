import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./styles";
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import eventsApi from "../../../api/event";
import types from "../../../redux/types";
import { useDispatch } from "react-redux";
import moment from "moment";
import ticketsApi from "../../../api/tickets-api";

const MovieForm = ({
  open,
  handleClose,
  handleAdd,
  cinema,
  date,
  jwt,
  setPrice
}) => {
  const classes = useStyles();
  const [movie, setMovie] = useState("");
  const [showtime, setShowtime] = useState("");
  const [ticket, setTicket] = useState("");
  const [movies, setMovies] = useState([]);
  const [tickets, setTickets] = useState([]);

  const [moviesLoading, setMoviesLoading] = useState(false);
  const [ticketsLoading, setTicketsLoading] = useState(false);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const extHandleee = () => {
    setMovie("");
    setShowtime("");
    setTicket("");
    handleClose();
  };

  const handleChange = ({ target }, inputField, validator) => {
    const inputCheck = validator ? validator : () => true;
    const { value } = target;
    if (inputCheck(value)) {
      inputField(value);
    }
  };

  const selectedMovie = movies?.find(item => item?.movie_id === movie);

  const showtimes =
    selectedMovie?.show_times?.filter(
      item =>
        moment(date, "YYYY-MM-DD").isSame(moment(item.show_time), "day") &&
        !!item?.seats_available
    ) ?? [];

  const selectedShowtime = showtimes?.find(
    item => item?.show_time_id === showtime
  );

  // const tickets = selectedShowtime?.tickets ?? [];

  const handleSubmit = async e => {
    e.preventDefault();
    if (!movie) {
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: "Select a movie"
        }
      });
      return;
    }
    if (!showtime) {
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: "Select a showtime"
        }
      });
      return;
    }
    if (!ticket) {
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: "Select a ticket"
        }
      });
      return;
    }
    handleAdd({
      movie,
      showtime,
      ticket
    });
    const ticketPrice = tickets?.find?.(item => `${item.ticket_id}` === ticket)
      ?.ticket_price;
    if (ticketPrice)
      setPrice(prevPrice => Number(prevPrice) + Number(ticketPrice));
    setMovie("");
    setShowtime("");
    setTicket("");
  };

  useEffect(() => {
    if (!cinema || !date) return;
    setMoviesLoading(true);
    setMovies([]);
    setMovie("");
    setShowtime("");
    setTicket("");
    eventsApi
      .getCinemaMovies(cinema, date, date, jwt)
      .then(res => {
        const movies = res?.data?.data;
        setMovies(movies);
        setMovie(movies.length ? movies[0]?.movie_id : "");
      })
      .finally(() => {
        setMoviesLoading(false);
      });
  }, [cinema, date, jwt]);

  useEffect(() => {
    if (!cinema || !showtime || !selectedShowtime?.cinema_screen_id) return;
    setTicketsLoading(true);
    setTickets([]);
    ticketsApi
      .fetchTicketTypes(
        jwt,
        cinema,
        selectedShowtime?.cinema_screen_id,
        showtime,
        jwt
      )
      .then(res => {
        const tickets = res?.data?.data?.tickets;
        const mappedTickets = tickets?.map(ticket => ({
          ticket_id: `${ticket?.product_id}`,
          ticket_name: ticket?.product_name,
          ticket_price: `${ticket?.product_price}`,
          display_price: `${ticket?.product_price}`
        }));
        setTickets(mappedTickets);
      })
      .finally(() => {
        setTicketsLoading(false);
      });
  }, [cinema, showtime, selectedShowtime?.cinema_screen_id, jwt]);

  return (
    <Dialog
      fullWidth="xl"
      open={open}
      onClose={extHandleee}
      aria-labelledby="max-width-dialog-title"
    >
      <div className={classes.selectMovieDialog}>
        <DialogTitle id="max-width-dialog-title">
          <Typography variant="h4">Add Movie</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <form
              id="movie-form"
              style={{ marginTop: "20px" }}
              onSubmit={handleSubmit}
              className={classes.form}
            >
              <FormControl variant="outlined" className={classes.formField}>
                <InputLabel> Select Movie </InputLabel>
                <Select
                  disabled={moviesLoading}
                  value={movie}
                  onChange={e => handleChange(e, setMovie)}
                  onBlur={e => handleChange(e, setMovie)}
                  name="movie"
                >
                  {movies?.map((movie, i) => {
                    return (
                      <MenuItem key={i} value={movie?.movie_id}>
                        {movie?.movie_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formField}>
                <InputLabel> Select Showtime </InputLabel>
                <Select
                  value={showtime}
                  onChange={e => handleChange(e, setShowtime)}
                  onBlur={e => handleChange(e, setShowtime)}
                  name="showtime"
                >
                  {showtimes?.map((showtime, i) => {
                    return (
                      <MenuItem key={i} value={showtime?.show_time_id}>
                        {moment(showtime?.show_time).format("hh:mmA")}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formField}>
                <InputLabel> Select Ticket </InputLabel>
                <Select
                  disabled={ticketsLoading}
                  value={ticket}
                  onChange={e => handleChange(e, setTicket)}
                  onBlur={e => handleChange(e, setTicket)}
                  name="ticket"
                >
                  {tickets?.map((ticket, i) => {
                    return (
                      <MenuItem key={i} value={ticket?.ticket_id}>
                        {ticket?.ticket_name} - ₦
                        {parseFloat(ticket?.ticket_price)?.toFixed(2)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            CLOSE
          </Button>
          <Button
            form="movie-form"
            type="submit"
            color="primary"
            variant="contained"
            disabled={!movie || !showtime || !ticket}
          >
            ADD MOVIE
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default MovieForm;
