import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px"
  },
  search: {
    maxWidth: "487px",
    width: "60%"
  },
  rightAction: {
    display: "flex",
    gap: "16px",
    width: "35%",
    alignItems: "center"
  },
  addBtn: {
    maxWidth: "197px",
    width: "50%"
  },
  title: {
    display: "flex",
    gap: "30px",
    alignItems: "center"
  },
  image: {
    objectFit: "cover"
  },
  downloadBtn: {
    maxWidth: "226px",
    width: "50%"
  },
  dot: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
    display: "inline-block"
  },
  status: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    borderRadius: "16px",
    padding: "2px 6px",
    fontSize: "12px",
    fontWeight: 400,
    color: "#FFFFFF",
    "& span": {
      lineHeight: "1.5"
    }
  },
  success: {
    backgroundColor: "#027A48"
  },
  error: {
    backgroundColor: "#B42318"
  },
  tableActionCol: {
    display: "flex",
    alignItems: "center"
  },
  formHeading: {
    fontSize: "40px",
    fontWeight: 500,
    color: "#FFFFFF",
    lineHeight: "46.88px",
    marginBottom: "32px"
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "22px"
  },
  formPair: {
    display: "flex",
    gap: "22px",
    width: "100%"
  },
  formSubHeading: {
    fontSize: "36px",
    fontWeight: 400,
    color: "#606063",
    lineHeight: "42.19px"
  },
  formField: {
    width: "100%"
  },
  imageUpload: {
    width: "100%"
  },
  actionBtnCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "14px",
    "& button": {
      width: "160px",
      height: "44px"
    }
  },
  selectMovies: {
    backgroundColor: "#0A0A0E",
    border: "6px dotted #24242C",
    minHeight: "204px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    justifyItems: "center",
    padding: "18px",
    "& > .action-btn": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      margin: "auto"
    },
    "& p": {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: "20px",
      lineHeight: "23.44px",
      letterSpacing: "0",
      textAlign: "center"
    },
    "& button": {
      fontWeight: "900",
      fontSize: "18px",
      lineHeight: "21.09px",
      letterSpacing: "0",
      padding: "14px 28px"
    }
  },
  selectMovieDialog: {
    backgroundColor: "#1C1C22",
    padding: "47px 0"
  },
  selectionItems: {
    display: "grid",
    gap: "12px",
    listStyle: "none",
    gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    alignItems: "center"
  },
  selectionItem: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    alignItems: "flex-start",
    "& img": {
      width: "209px",
      height: "152px",
      objectFit: "cover"
    },
    "& .movie-title": {
      fontSize: "20px",
      fontFamily: "Roboto",
      lineHeight: "30px",
      letterSpacing: "0",
      color: "white",
      fontWeight: "400"
    },
    "& .movie-pill-title": {
      fontSize: "14px",
      fontFamily: "Roboto",
      lineHeight: "16px",
      letterSpacing: "0",
      color: "#00CC73",
      fontWeight: "500"
    },
    "& .movie-pill": {
      fontSize: "14px",
      fontFamily: "Roboto",
      fontWeight: "700",
      lineHeight: "25px",
      letterSpacing: "0.33px",
      backgroundColor: "white",
      color: "black",
      padding: "5px 13px",
      borderRadius: "22px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    "& .selection-header": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "10px"
    }
  },
  quantitySelector: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    "& .quantity": {
      fontFamily: "14px",
      fontWeight: 500,
      lineHeight: "16.41px",
      color: "white"
    },
    "& button": {
      cursor: "pointer"
    }
  }
}));

export default useStyles;
