import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import useStyles from "./styles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import types from "../../../redux/types";
import { getRequestError } from "../../../utils/functions";
import moment from "moment";
import { useHistory } from "react-router-dom";
import cinemasApi from "../../../api/cinemas";
import fileApi from "../../../api/file";
import MovieForm from "./MovieForm";
import MovieSelection from "./MovieSelection";
import { uid } from "uid";
import concessionsApi from "../../../api/concessions-api";
import Decrement from "../../../vectors/Decrement";
import Increment from "../../../vectors/Increment";
import { DataGrid } from "@material-ui/data-grid";
import empty from "../../../assets/images/empty-concessions.png";

const ACCEPTED_FILES = ["image/x-png", "image/gif", "image/jpeg", "image/jpg"];

const FileUpload = ({ url, setUrl, name }) => {
  const [isUploading, setIsUploading] = useState(false);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const { imageUpload } = useStyles();

  const uploadImage = async (type, file) => {
    const payload = new FormData();
    payload.append("file", file);

    setIsUploading(true);

    try {
      const response = await fileApi.uploadImageWithFile(payload);
      const image = response.data.data;
      setUrl(image);
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(error)
        }
      });
    }
  };

  return (
    <div className={imageUpload}>
      <div className="inline-uploader">
        <span className="btn-file">
          {name}
          <input
            type="file"
            className="custom-file-input"
            onChange={e => {
              e.preventDefault();
              uploadImage("thumbnail", e.target.files[0]);
            }}
            accept={ACCEPTED_FILES.map(type => type).join(",")}
          />
        </span>
        <span className="side-text">
          {isUploading ? "Uploading..." : (url && url) || "No file chosen"}
        </span>
      </div>
    </div>
  );
};

const EventForm = ({ event, handleEvent, formTitle, jwt, type }) => {
  const [title, setTitle] = useState(event.title);
  const [description, setDescription] = useState(event.description);
  const [image, setImage] = useState(event.image);
  const [category, setCategory] = useState(event.category);
  const [selections, setSelections] = useState(event.selections);
  const [date, setDate] = useState(event.date);
  const [time, setTime] = useState(event.time);
  const [price, setPrice] = useState(event.price);
  const [selectedConcessions, setSelectedConcessions] = useState(
    event.concessions
  );
  // const [ticketsAvailable, setTicketsAvailable] = useState(
  //   event.ticketsAvailable
  // );
  const [loading, setLoading] = useState();
  const [cinemasLoading, setCinemasLoading] = useState(false);
  const [cinemas, setCinemas] = useState([]);
  const [concessions, setConcessions] = useState([]);
  const [concessionsLoading, setConcessionsLoading] = useState(false);
  const [selectedCinema, setSelectedCinema] = useState(event.cinema);
  const [isOpenMovieForm, setisOpenMovieForm] = useState(false);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    setCinemasLoading(true);
    cinemasApi.fetchCinemas(jwt).then(res => {
      const allCinemas =
        res?.data?.data
          ?.flatMap(item => item?.cinemas)
          ?.sort((a, b) => a.cinema_name.localeCompare(b.cinema_name)) ?? [];
      setCinemas(allCinemas);
      setSelectedCinema(
        allCinemas?.find?.(cinema =>
          cinema?.cinema_name?.toLowerCase()?.includes("lekki")
        )?.id ?? null
      );
      setCinemasLoading(false);
    });
  }, [jwt]);

  useEffect(() => {
    if (selectedCinema) {
      setConcessionsLoading(true);
      concessionsApi
        .fetchConcessions(jwt, selectedCinema)
        .then(res => {
          const allConcessions =
            res?.data?.data?.concessions
              ?.flatMap(item => item.products)
              ?.map(item => ({
                id: item.product_id,
                price: item.product_price,
                image: item.image_url,
                name: item.product_name
              }))
              ?.filter(item => item.price > 0) ?? [];

          setConcessions(allConcessions);
        })
        .finally(() => {
          setConcessionsLoading(false);
        });
    }
  }, [jwt, selectedCinema]);

  const handleChange = ({ target }, inputField, validator) => {
    const inputCheck = validator ? validator : () => true;
    const { value } = target;
    if (inputCheck(value)) {
      inputField(value);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (
        !title ||
        !description ||
        !image ||
        !selectedCinema ||
        !date ||
        !time ||
        !selections?.length ||
        !price
      ) {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: "Please fill the form"
          }
        });
        return;
      }
      const datetime = moment.utc(`${date} ${time}`, "YYYY-MM-DD HH:mm");

      let payload = {
        name: title,
        description: description,
        date: datetime.toISOString(),
        cinema_id: selectedCinema,
        image: image,
        ticket_ids: selections
          ?.map(selection => `${selection.ticket}`)
          ?.join(","),
        movie_ids: selections
          ?.map(selection => `${selection.movie}`)
          ?.join(","),
        showtime_ids: selections
          ?.map(selection => `${selection.showtime}`)
          ?.join(","),
        price: parseFloat(price),
        event_category: category
      };

      if (selectedConcessions.length) {
        payload["concession_ids"] = selectedConcessions
          ?.map(concession => `${concession.id}`)
          ?.join(",");
        payload["concession_quantities"] = selectedConcessions
          ?.map(concession => `${concession.quantity}`)
          ?.join(",");
      }

      const res = await handleEvent(payload);
    } catch (e) {
      dispatch({
        type: ALERT_OPEN,
        payload: {
          severity: "error",
          message: getRequestError(e)
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const handleIncrement = id => {
    setSelectedConcessions(prevConcessions => {
      const exists = prevConcessions.find(item => item.id === id);
      if (exists) {
        return prevConcessions.map(item =>
          item.id === id ? { ...item, quantity: item.quantity + 1 } : item
        );
      }
      return [...prevConcessions, { id, quantity: 1 }];
    });
  };

  const handleDecrement = id => {
    setSelectedConcessions(prevConcessions =>
      prevConcessions
        .map(item =>
          item.id === id ? { ...item, quantity: item.quantity - 1 } : item
        )
        .filter(item => item.quantity > 0)
    );
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 800,
      renderCell: params => {
        return (
          <div className={classes.title}>
            <img
              className={classes.image}
              alt={params.row.name}
              src={params.row.image || empty}
              width={57}
              height={46}
            />
            <span>{params.row.name}</span>
          </div>
        );
      }
    },
    {
      field: "price",
      headerName: "Price",
      width: 200
    },
    {
      field: "id",
      headerName: "Quantity",
      width: 128,
      renderCell: params => {
        return (
          <div className={classes.quantitySelector}>
            <button
              disabled={
                !(
                  selectedConcessions?.find(
                    concession => concession?.id === params.row.id
                  )?.quantity ?? 0
                )
              }
              type="button"
              onClick={() => {
                setPrice(prevPrice => Number(prevPrice) - params.row.price);
                handleDecrement(params.row.id);
              }}
            >
              <Decrement />
            </button>
            <p className="quantity">
              {selectedConcessions?.find(
                concession => concession?.id === params.row.id
              )?.quantity ?? 0}
            </p>
            <button
              type="button"
              onClick={() => {
                setPrice(prevPrice => Number(prevPrice) + params.row.price);
                handleIncrement(params.row.id);
              }}
            >
              <Increment />
            </button>
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <MovieForm
        setPrice={setPrice}
        cinema={selectedCinema}
        date={date}
        handleAdd={selection => {
          setSelections(prevSelections => [
            ...prevSelections,
            {
              ...selection,
              id: uid(8)
            }
          ]);
          setisOpenMovieForm(false);
        }}
        handleClose={() => {
          setisOpenMovieForm(false);
        }}
        jwt={jwt}
        open={isOpenMovieForm}
      />
      <h1 className={classes.formHeading}>{formTitle}</h1>
      <form
        id="event-form"
        className={classes.form}
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <h2 className={classes.formSubHeading}>OVERVIEW</h2>
        <div className={classes.formPair}>
          <TextField
            name="eventTitle"
            onChange={e =>
              handleChange(e, setTitle, value => value.length <= 100)
            }
            value={title}
            variant="outlined"
            label="Event Name"
            className={classes.formField}
            required
          />
          <TextField
            name="eventCategory"
            onChange={e =>
              handleChange(e, setCategory, value => value.length <= 100)
            }
            value={category}
            variant="outlined"
            label="Category"
            className={classes.formField}
            required
          />
        </div>

        <FileUpload name="CHOOSE  EVENT IMAGE" url={image} setUrl={setImage} />
        <div className={classes.formPair}>
          <FormControl
            disabled={cinemasLoading}
            variant="outlined"
            className={classes.formField}
          >
            <InputLabel> Location </InputLabel>
            <Select
              disabled={cinemasLoading}
              value={selectedCinema}
              onChange={e => {
                handleChange(e, setSelectedCinema);
                setSelections([]);
                setSelectedConcessions([]);
                setPrice("");
              }}
              onBlur={e => {
                handleChange(e, setSelectedCinema);
                setSelections([]);
                setSelectedConcessions([]);
                setPrice("");
              }}
              name="location"
            >
              {cinemas?.map((cinema, i) => {
                return (
                  <MenuItem key={i} value={cinema?.id}>
                    {cinema?.cinema_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            id="eventDate"
            name="eventDate"
            label="Date"
            type="date"
            value={moment(date).format("YYYY-MM-DD")}
            onChange={e => {
              handleChange(e, setDate);
              setSelections([]);
            }}
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            className={classes.formField}
            required
          />
          <TextField
            id="eventTime"
            name="eventTime"
            label="Time"
            type="time"
            value={time}
            onChange={e => handleChange(e, setTime)}
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            className={classes.formField}
            required
          />
        </div>
        <MovieSelection
          cinema={selectedCinema}
          date={date}
          handleAddAction={() => {
            setisOpenMovieForm(true);
          }}
          jwt={jwt}
          selections={selections}
          setPrice={setPrice}
          onRemoveSelection={id => {
            setSelections(prevSelections =>
              prevSelections?.filter(item => item.id !== id)
            );
          }}
        />
        <TextField
          name="eventDescription"
          onChange={e => handleChange(e, setDescription)}
          value={description}
          multiline
          rows={3}
          variant="outlined"
          label="Description"
          style={{ flex: 1 }}
          required
        />
        <div className={classes.formPair}>
          <TextField
            name="eventPrice"
            prefix="₦"
            onChange={e =>
              handleChange(e, setPrice, value => !isNaN(value.length))
            }
            value={price}
            variant="outlined"
            label="Ticket Price"
            className={classes.formField}
            required
          />
        </div>
        <h3 className={classes.formSubHeading}>Concession</h3>
        <DataGrid
          rows={concessions}
          columns={columns}
          pageSize={10}
          rowHeight={80}
          headerHeight={60}
          disableColumnMenu={true}
          autoHeight={true}
          loading={concessionsLoading}
        />
        <div className={classes.actionBtnCont}>
          <Button
            onClick={() => {
              history.push("/dashboard/event-cinema");
            }}
            color="primary"
            variant="outlined"
            disabled={loading}
          >
            CANCEL
          </Button>
          <Button
            form="event-form"
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {type === "add"
              ? loading
                ? "ADDING..."
                : "SAVE EVENT"
              : loading
              ? "EDITING"
              : "EDIT EVENT"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EventForm;
