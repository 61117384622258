import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import { Button } from "@material-ui/core";
import PaperedInput from "../../../components/Inputs/PaperedInput";
import api from "../../../api/event";
import { Alert } from "@material-ui/lab";
import { debounce } from "../../../utils/functions";
import useStyles from "./styles";
import Vectors from "./vectors";
import { useHistory } from "react-router-dom";
import moment from "moment";
import types from "../../../redux/types";
import { getRequestError } from "../../../utils/functions";
import { useDispatch } from "react-redux";
import DeleteEvent from "./DeleteEvent";

const AllEvents = () => {
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [events, setEvents] = useState([]);
  const [error, setError] = useState({ isError: false, message: "" });
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const { jwt } = useSelector(state => state.user.user);

  const history = useHistory();

  const classes = useStyles();

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const handleOpenClose = (setter, value) => {
    return setter(value);
  };

  const handleOpenDeleteModal = (e, id) => {
    e.preventDefault();
    setSelectedEventId(id);
    setOpenDelete(true);
  };

  const fetchEvents = page => {
    setLoading(true);
    setError({ isError: false, message: "" });
    api
      .getList(`Bearer ${jwt}`, page + 1)
      .then(res => {
        const mappedRes =
          res?.data?.data?.map(item => ({
            id: item.id,
            isConcession: !!item?.concession_ids,
            isMerchandise: false,
            timeAndDate: moment(item.date).format("MMMM, D YYYY | h:mma"),
            location: item?.cinema_name ?? "-",
            name: item.name
          })) ?? [];
        console.log(mappedRes?.length ?? 0);
        setEvents(mappedRes);
        setTotalData(mappedRes?.length);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        setError({ isError: true, message: `${err}` });
        return err;
      });
  };

  const handleDelete = id => {
    return api
      .deleteEvent(`Bearer ${jwt}`, id)
      .then(res => {
        setOpenDelete(false);
        fetchEvents(0);
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };
  useEffect(() => {
    fetchEvents(page);
  }, [page, jwt]);

  const columns = [
    {
      field: "name",
      headerName: "Event Name",
      width: 280
    },
    { field: "location", headerName: "Location", width: 260 },
    {
      field: "timeAndDate",
      headerName: "Event Time & Date ",
      width: 192
    },
    {
      field: "isConcession",
      headerName: "Concession ",
      width: 146,
      renderCell: params => {
        return (
          <p
            className={`${classes.status} ${
              params.row.isConcession ? classes.success : classes.error
            }`}
          >
            <span className={classes.dot} />
            <span>
              {params.row.isConcession ? "Available" : "Not available"}
            </span>
          </p>
        );
      }
    },
    {
      field: "isMerchandise",
      headerName: "Merchandise",
      width: 146,
      renderCell: params => {
        return (
          <p
            className={`${classes.status} ${
              params.row.isMerchandise ? classes.success : classes.error
            }`}
          >
            <span className={classes.dot} />
            <span>
              {params.row.isMerchandise ? "Available" : "Not available"}
            </span>
          </p>
        );
      }
    },
    {
      field: "id",
      headerName: " ",
      width: 120,
      renderCell: params => {
        return (
          <div className={classes.tableActionCol}>
            <button onClick={e => handleOpenDeleteModal(e, params.row.id)}>
              <Vectors.Delete />
            </button>
            <button
              onClick={() => {
                history.push(`/dashboard/event-cinema/${params.row.id}`);
              }}
            >
              <Vectors.Edit />
            </button>
          </div>
        );
      }
    }
  ];

  console.log(events);

  return (
    <div>
      <DeleteEvent
        jwt={jwt}
        recordId={selectedEventId}
        open={openDelete}
        handleClose={() => handleOpenClose(setOpenDelete, false)}
        handleDelete={handleDelete}
      />
      <>
        <div className={classes.header}>
          <div className={classes.search}>
            <PaperedInput
              onChange={e => {
                e.persist();
                debounce(() => setSearchInput(e.target.value), 2000);
              }}
              placeholder="Search for Event"
            />
          </div>
          <div className={classes.rightAction}>
            <div className={classes.addBtn}>
              <Button
                onClick={() => {
                  history.push("/dashboard/event-cinema/create");
                }}
                style={{ width: "100%" }}
                variant="outlined"
                color="primary"
              >
                + ADD EVENT{" "}
              </Button>
            </div>
            <div className={classes.downloadBtn}>
              <CSVLink data={events} filename={"payment-logs.csv"}>
                <Button variant="contained" color="primary">
                  <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
          {error.isError ? (
            <Alert severity="error">{error.message}</Alert>
          ) : (
            <DataGrid
              rows={events}
              columns={columns}
              pageSize={10}
              rowHeight={50}
              headerHeight={60}
              disableColumnMenu={true}
              page={page}
              autoHeight={true}
              rowCount={totalData}
              loading={loading}
              onPageChange={page => {
                setPage(page);
              }}
              rowsPerPageOptions={[10]}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default AllEvents;
