import React, { useEffect, useState } from "react";
import EventForm from "./EventForm";
import { connect } from "react-redux";
import eventApi from "../../../api/event";
import moment from "moment";
import { useDispatch } from "react-redux";
import types from "../../../redux/types";
import { useParams, useHistory } from "react-router-dom";
import { getRequestError } from "../../../utils/functions";
import { uid } from "uid";

const CreateEvent = ({ user }) => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const history = useHistory();

  const params = useParams();

  const handleAdd = async data => {
    try {
      const res = await eventApi.addEvent(`Bearer ${user.jwt}`, data);
    } catch (e) {}
  };

  useEffect(() => {
    if (params.id) {
      eventApi
        .getEvent(`Bearer ${user.jwt}`, params.id)
        .then(res => {
          const data = res.data.data;
          const showtimeIds = data.showtime_ids.split(",").map(s => s.trim());
          const movieIds = data.movie_ids.split(",").map(s => s.trim());
          const ticketIds = data.ticket_ids.split(",").map(s => s.trim());
          const ids = data?.concession_ids?.split(",")?.map(id => id.trim());
          const quantities = data?.concession_quantities
            ?.split(",")
            ?.map(qty => qty.trim());
          setData({
            title: data.name,
            cinema: data.cinema_id,
            image: data.image,
            description: data.description,
            category: data.event_category,
            selections: showtimeIds.map((showtime, index) => ({
              ticket: parseInt(ticketIds[index], 10),
              movie: parseInt(movieIds[index], 10),
              showtime: parseInt(showtime, 10),
              id: uid(8)
            })),
            concessions: ids?.length
              ? ids.map((id, index) => ({
                  id: parseInt(id, 10),
                  quantity: parseInt(quantities[index], 10)
                }))
              : [],
            date: moment(data.date).format("YYYY-MM-DD"),
            time: moment(data.date).format("HH:mm"),
            price: data.price
          });
        })
        .catch(err => {
          return err;
        });
    }
  }, [params.id]);
  return data ? (
    <EventForm
      jwt={user.jwt}
      event={data}
      formTitle={"Edit an Event"}
      handleEvent={async data => {
        try {
          await eventApi.editEvent(`Bearer ${user.jwt}`, data, params.id);
          history.goBack();
        } catch (e) {
          dispatch({
            type: ALERT_OPEN,
            payload: {
              severity: "error",
              message: getRequestError(e)
            }
          });
          return e;
        }
      }}
      type={"add"}
    />
  ) : null;
};

const mapStateToProps = state => ({
  user: state.user.user
});
export default connect(mapStateToProps)(CreateEvent);
