import api from "./instance";

export default {
  getList: (token, page) =>
    api
      .get(`/events/admin?pageNo=${page}&pageSize=10`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  getCinemaMovies: (cinemaId, startDate, endDate, token) =>
    api
      .get(
        `/movie/new-list?cinema_id=${cinemaId}&start_date=${startDate}&end_date=${endDate}&platform=WEB`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      .then(res => res),
  addEvent: (token, payload) =>
    api
      .post("/events", payload, {
        headers: { Authorization: token }
      })
      .then(res => res),
  getEvent: (token, id) =>
    api
      .get(`/events/${id}`, {
        headers: { Authorization: token }
      })
      .then(res => res),
  editEvent: (token, payload, id) =>
    api
      .put(`/events/${id}`, payload, {
        headers: { Authorization: token }
      })
      .then(res => res),
  deleteEvent: (token, id) =>
    api
      .delete(`/events/${id}`, {
        headers: { Authorization: token }
      })
      .then(res => res)
};
