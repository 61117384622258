import React, { useState } from "react";
import DeleteDialog from "../../../components/DeleteDialog";

const DeleteEvent = ({ open, recordId, handleClose, handleDelete }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteAction = async () => {
    setLoading(true);
    await handleDelete(recordId).then(() => {
      setLoading(false);
    });
  };

  return (
    <DeleteDialog
      handleClose={handleClose}
      handleDelete={handleDeleteAction}
      loading={loading}
      open={open}
      title="Delete event"
    />
  );
};

export default DeleteEvent;
